import './main.css';
import NavBar from '../Navbar/navbar';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';

function Main() {
  return (
    <div>
      <div className="TitleBack">
        <NavBar/>
        <div className = "Title">
          <p className="TitleAss">Association Of</p>
          <p className="TitleWom">Women In</p>
          <p className="TitlePul">Pulmonology</p>
          <p className="TitlePul2">Women In Pulmonology</p>
        </div>
        <div class="iconHolder">
          <a href="https://twitter.com/WomenInPul" target="_blank">
            <TwitterIcon className="icon" fontSize= "large" style={{color: '#1DA1F2'}}/>
          </a>
          <a href="mailto:wpulmonology@gmail.com" target="_blank">
            <EmailIcon className="icon" fontSize= "large" style={{color: '#FF6969'}}/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Main;
