import thilaga from './images/dr.Thilaga.png';
import gayathri from './images/gayathri.png';
import jayanthi from './images/jayanthi.png';
import lakshmi from './images/laksmimurali.png';
import linga from './images/linga.png';
import sangeetha from './images/sangeetha.png';
import sri from './images/sareedevi.png';
import vijaya from './images/vijayasaratha.png';
import temp from './images/temp.png';
import aruna from './images/aruna.png';
import nancy from './images/nancy.png';
import Vt from './images/VT.png'
import asha from './images/asha.png'
import meenakshi from './images/meenakshi.png'

export const doctors = [
    {
        name: "Dr K Vijayasaratha",
        role: "Founder",
        facebook: "https://www.facebook.com/",
        email: "mailto:midlandsrespiratory@gmail.com",
        src: vijaya
    },
    {
        name: "Dr Nalini Jeyanthi",
        role: "President",
        facebook: "https://www.facebook.com/",
        email: "mailto:nalinijayanthi59@gmail.com",
        src: jayanthi
    },
    {
        name: "Dr Sangeetha",
        role: "Vice President",
        facebook: "https://www.facebook.com/",
        email: "mailto:meda_sangeetha@hotmail.com",
        src: sangeetha
    },
    {
        name: "Dr Gayatri",
        role: "General secretary",
        facebook: "https://www.facebook.com/",
        email: "mailto:drgaya3ram@gmail.com",
        src: gayathri
    },
    {
        name: " Dr Lakshmi Murali",
        role: "Joint secretary",
        facebook: "https://www.facebook.com/",
        email: "mailto:lak_murali668@yahoo.co.in",
        src: lakshmi
    },
    {
        name: "Dr Thilagavathy",
        role: "Treasurer",
        facebook: "https://www.facebook.com/",
        email: "mailto:drgthilaga@yahoo.com",
        src: thilaga
    },
    {
        name: "Dr S Aruna",
        role: "Joint treasurer",
        facebook: "https://www.facebook.com/",
        email: "mailto:draruna.shanmuganathan@gmail.com",
        src: aruna
    },
    {
        name: "Dr K.Vijayasaratha",
        role: "International Scientific Advisor",
        facebook: "https://www.facebook.com/",
        email: "mailto:midlandsrespiratory@gmail.com",
        src: vijaya
    },
    {
        name: "Dr.Nancy",
        role: "Governing body member",
        facebook: "https://www.facebook.com/",
        email: "mailto:nancy_ranjit@yahoo.co.in",
        src: nancy
    },
    {
        name: "Dr Asha Fredrick",
        role: "Governing body member",
        facebook: "https://www.facebook.com/",
        email: "mailto:afashafrederick@gmail.Com",
        src: asha
    },
    {
        name: "Dr Linga",
        role: "Governing body member",
        facebook: "https://www.facebook.com/",
        email: "mailto:drlinga2@gmail.com",
        src: linga
    },
    {
        name: "Dr N. Meenakshi",
        role: "Chief scientific advisor",
        facebook: "https://www.facebook.com/",
        email: "mailto:paddy_2020@yahoo.com",
        src: meenakshi
    },
    {
        name: "Dr Sridevi",
        role: "Chief scientific advisor",
        facebook: "https://www.facebook.com/",
        email: "mailto:drsreedevikotha@gmail.com",
        src: sri
    },
    {
        name: "Dr VT",
        role: "Chief scientific advisor",
        facebook: "https://www.facebook.com/",
        email: "mailto:drvthanasekaraan@yahoo.com",
        src: Vt
    },
]