export const aboutinfo = [
    {
        title: 'Introduction',
        info: 'The field of medicine has seen increasing enrolment rates of women medical students in India, accounting for over 50%. However, this number reduces significantly during postgraduate training and post-doctoral courses.',
        title2: 'Shared Values and Goals',
        info2: 'The Women in Pulmonology group aims to promote a robust and vibrant profession, deliver cutting-edge patient care, encourage research and publications by its members, help secure funding for professional opportunities, and foster healthy social bonds. The group strives in actively recruiting more women into the field to embrace challenges and stimulating careers.'
    },
    {
        title: 'Professional Development Preferences for Women Physicians',
        info: 'Women physicians prioritize certain factors in their professional development. These include stable working hours, family and female-friendly environments, access to positive role models, mentors and opportunities for career stimulation and growth. WIP gives an opportunity for women doctors and pulmonologist to show their skills and a platform to expose themselves and their talents  to the world.',
        title2: 'The Role of Advocacy',
        info2: 'Through advocacy, women can strive for leadership roles, competitive pay, and work-life balance, and support each other\'s careers and professions.'
    },
    {
        title: 'Respiratory Disease Burden Among Women',
        info: 'Respiratory diseases, particularly asthma and COPD, impose a substantial burden on women in India. Factors like indoor air pollution, biomass fuel usage, undernutrition, and historical health neglect contribute to this burden. There is a pressing need for more women pulmonologists to address this growing demand and promote the health of vulnerable women with respiratory diseases.',
        title2: 'Characteristics of a Pulmonologist',
        info2: 'To excel in pulmonology, one needs to have a strong general medical knowledge base, needs to collaborate with multidisciplinary teams, be competent in respiratory procedural skills, be capable of recognition of multisystem diseases, have empathy towards patients with chronic pulmonary disorders, and be open to new ideas. Above all logic has to be applied.  These are some essential qualities, which are often exemplified by women pulmonologists.'
    },
    {
        title: 'Women in Pulmonology Group',
        info: 'In Tamilnadu, India, a pioneering Women in Pulmonology group has been functioning since 2018 to support and guide women pulmonologists. This platform encourages not only discussions on various educational topics but includes networking, which was more useful during the COVID times to discuss various treatments and strategies across the world.  The forum helps to promote women pulmonologists and provides mentorship to many. The group boosts confidence among its members and facilitates professional growth.',
        title2: 'Conclusion',
        info2: 'The commitment to advancing women in pulmonology is crucial in creating a more equitable and diverse profession. Advocacy, support, and a shared vision of professional growth can break barriers and pave the way for a brighter future in pulmonology for women.'
    },
];
