import './card.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

function Card(props) {
  return (
    <div class="cardHolder">
        <div class="circle-1"></div>
        <img src={props.src} className="image"/>

        <p className= "name">{props.name}</p>
        <p className= "role">{props.role}</p>
        <div className='socialHold'>
          <ul className= "social">
            <div className='iconexpand2'>
              <a href= {props.email}  target="_blank">
                <EmailIcon className="iconCard2"/>
              </a>
            </div>
          </ul>
        </div>
    </div>
  );
}

export default Card;
