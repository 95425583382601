import './navbar-op.css';

function NavBarOp(props) {
  return (
    <div>
      <a href={props.link} className="removea">
        <div className='option'>
          {props.name}
        </div>
      </a>
    </div>

  );
}

export default NavBarOp;
