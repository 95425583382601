import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './components/Landingpage/landing';
import AboutPage from './components/AboutPage/aboutPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Landing />} />
          <Route exact path='/about' element={<AboutPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
