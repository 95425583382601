import './landing.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './main';
import About from '../Aboutus/about';
import Meet from '../MeetTeam/meet';
import Contact from '../contact/contact';

function Landing() {
  return (
    <div className="App">
      <Main/>
      <About/>
      <Meet/>
      <Contact/>
    </div>
  );
}

export default Landing;
