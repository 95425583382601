import './contact.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

function Contact() {
  return (
    <div class="contactwrap">
      <p className="title">Contact Us</p>
      <div className= "doublebutton">
        <a href="https://twitter.com/WomenInPul" target="_blank">
          <button className="button1" type="submit">Twitter</button>
        </a>
        <a href = "mailto:wpulmonology@gmail.com" target="_blank">
          <button className="button2">Email</button>
        </a>
      </div>
      <div className= "credits">
        <p className="credit">Website Creation Credits: Sargunan Gurumurthy</p> 
        <div class="iconHolderCredit">
          <a href="https://www.linkedin.com/in/sargu/" target="_blank">
            <LinkedInIcon className="iconcredit" fontSize= "medium" style={{color: '#0077b5'}}/>  
          </a>
          <a href = "mailto:sargunan1213@gmail.com" target="_blank">
            <EmailIcon className="iconcredit" fontSize= "medium" style={{color: '#FF6969'}}/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
