import './about.css';

function About() {
  return (
    <div>
      <div id='about'>
      <div className="titleback">
        <p className="title">About Us</p>
      </div>
      </div>
      <p className="aboutinfo">
      The field of medicine has seen increasing enrolment rates of women medical students in India, accounting for over 50%. However, this number reduces significantly during postgraduate training and post-doctoral courses.The commitment to advancing women in pulmonology is crucial in creating a more equitable and diverse profession. Advocacy, support, and a shared vision of professional growth can break barriers and pave the way for a brighter future in pulmonology for women.
      <br/>
      <br/>
      <div className="hov">
        <a class="read" href='/about'>Read more</a>
      </div>
      </p>
      
    </div>
  );
}

export default About;