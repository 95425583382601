import './aboutsection.css';

function AboutSection(props) {
  return (
    <div>
      <div className="split">
        <div className="sectiontitle">{props.title}</div>
        <div className="sectioninfo">{props.info}</div>
      </div>
      <div className="split2">
        <div className="sectiontitle2">{props.title2}</div>
        <div className="sectioninfo2">{props.info2}</div>
      </div>
    </div>
  );
}

export default AboutSection;
