import './navbar.css';
import NavBarOp from './navbar-op';

function NavBar() {
    const options = [['Meet Our Team','#meet'],['About Us','/about']]
  return (
   <div className = "Navbar">
        {
            options.map(op => {
                return (
                    <NavBarOp name={op[0]} link={op[1]}></NavBarOp>
                )
            })
        }    
   </div>
  );
}

export default NavBar;
