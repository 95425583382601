import './meet.css';
import Card from '../Profilecard/card';
import { doctors } from '../../doctors';


function Meet() {
  return (
    <div className="meetHolder" id="meet">
      <p className="title">Meet Our Team</p>
      <div className="cards">
          
          {doctors.map(doctor => {
              return(
                  <Card 
                      src={doctor.src} 
                      name = {doctor.name} 
                      role={doctor.role} 
                      fb= {doctor.facebook} 
                      email = {doctor.email}
                  />
              )
          })}
      </div>
    </div>
    
  );
}

export default Meet;
