import './aboutPage.css';
import { aboutinfo, conclusion } from '../../aboutinfo';
import AboutSection from './aboutsection';
// import Conclusion from './conclusion';
import HomeIcon from '@mui/icons-material/Home';

function AboutPage() {
  return (
    <div>
      <div className="AboutPageHeader">
          <p className="title">About Us</p>
      </div>
      <div className="sectionholder">
              {aboutinfo.map(section => {
                return(
                    <AboutSection 
                        title={section.title} 
                        info = {section.info} 
                        title2={section.title2} 
                        info2 = {section.info2} 
                    />
                )
              })}
      </div>
      {/* <div className="conclusionholder">
              {conclusion.map(section => {
                  return(
                      <Conclusion
                        title={section.title} 
                        info = {section.info}/>
                  )
                })}
      </div> */}
      <div className= "aboutparral">
          <a href={"/"} className="removea">
            <div className='aboutop'>
              Home
            </div>
          </a>
          <a href={"/about"} className="removea">
            <div className='aboutop'>
              Back To Top
            </div>
          </a>
      </div>
    </div>
  );
}

export default AboutPage;
